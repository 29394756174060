<template>
  <div v-if="itemsCont">
    <van-tree-select
      height="100vh"
      :items="items"
      :main-active-index.sync="activeIndex"
      @click-nav="clearItem(items[activeIndex].targetEntityList)"
    >
      <template #content>
        <div class="choose">
          <div class="title">突变位点选择</div>
        </div>
        <ul v-if="itemsCont">
          <li
            @click="changeActiveId(item)"
            v-for="(item, index) in itemsCont[activeIndex].targetEntityList"
            :key="index"
            :class="item.activeId == true ? 'active' : 'unactive'"
          >
            {{ item.target }}
          </li>
        </ul>
        <div class="footer">
          <van-sticky>
            <div class="button-flex">
              <div class="button uncomfirm" @click="unSaveData()">取消</div>
              <div class="button comfirm" @click="saveData()">保存</div>
            </div>
          </van-sticky>
        </div>
      </template>
    </van-tree-select>
    <share />
  </div>
</template>
<script>
import Share from "../../components/wechat/share.vue";
export default {
  components: { Share },
  data() {
    return {
      activeIndex: 0,
      items: [],
      itemsCont: null,
      targetId: [],
      targetIdDefault: [], //默认参数
      targetListDefault: [],
      targetList: [],
      illness: null,
    };
  },
  methods: {
    showIllness() {
      this.$http.post("/illness").then((res) => {
        console.log(res.data.data);

        if (res.data.data) {
          this.items = res.data.data.filter((el) => (el.text = el.illness));
          this.itemsCont = res.data.data;
          console.log("itemsCont", this.itemsCont);

          for (let i = 0; i < this.itemsCont.length; i++) {
            this.itemsCont[i]?.targetEntityList.forEach((el) => {
              console.log(this.targetId);
              this.$set(el, "activeId", this.targetId.includes(el.id));
            });
          }
        }
      });
    },
    changeActiveId(item) {
      console.log(item);
      console.log(this.targetId);
      console.log(this.targetList);

      // this.targetId = []
      // this.targetList = []
      // this.$store.commit('setTargetId', this.targetId)
      // this.$store.commit('setTargetList', this.targetList)

      item.activeId = !item.activeId;

      console.log(item.activeId);

      if (item.activeId === true && this.targetId.indexOf(item.id) === -1) {
        this.targetId.push(item.id);
        this.targetList.push(item.target);
      }

      if (item.activeId === false) {
        for (let i = 0; i < this.targetId.length; i++) {
          if (this.targetId[i] === item.id) {
            this.targetId.splice(i, 1);
          }
        }

        for (let i = 0; i < this.targetList.length; i++) {
          if (this.targetList[i] === item.target) {
            this.targetList.splice(i, 1);
          }
        }
      }
    },
    saveData() {
      console.log(
        this.items[this.activeIndex].illness,
        this.targetId,
        this.targetList
      );

      this.$store.commit("setTargetId", this.targetId);
      this.$store.commit("setTargetList", this.targetList);
      this.$store.commit("setIllness", this.items[this.activeIndex].illness);
      this.$store.commit("setIllnessId", this.items[this.activeIndex].id);
      this.$store.commit("setActiveIndex", this.activeIndex);
      this.$store.commit("setUidData", 2);
      // this.$router.push({
      //   name: 'Login',
      //   query: { uidData: 2, relation: this.$store.getters.relation },
      // })
      this.$router.go(-1);
    },
    unSaveData() {
      console.log(this.targetIdDefault, "this.targetIdDefault");
      this.$store.commit("setTargetId", this.targetIdDefault);
      this.$store.commit("setTargetList", this.targetListDefault);
      this.$store.commit("setUidData", 2);
      this.$router.go(-1);
      // this.$router.push({ name: 'Login', query: { uidData: 2 } })
    },
    clearItem(items) {
      console.log(items);
      this.targetId = [];
      this.targetList = [];

      items.forEach((el) => {
        el.activeId = false;
        console.log(el.activeId);
      });

      this.$store.commit("setTargetId", this.targetId);
      this.$store.commit("setTargetList", this.targetList);
      console.log(this.targetId, this.targetList);
    },
  },
  mounted() {
    if (this.$store.getters.activeIndex != 0 || this.$store.getters.targetId) {
      this.activeIndex = this.$store.getters.activeIndex;
      this.targetId = this.$store.getters.targetId;
      this.targetIdDefault = [...this.targetId];
      this.targetList = this.$store.getters.targetList;
      this.targetListDefault = [...this.targetList];
    }

    this.showIllness();
  },
};
</script>
<style lang="scss" scoped>
.choose {
  margin-left: 10px;
  .title {
    text-align: left;
    margin: 25px 0px;
    font-size: 15px;
    color: #333;
  }
}

::v-deep .van-sidebar-item--select::before {
  height: 50px;
  background: #0067f2;
}

::v-deep .van-sidebar-item__text {
  color: #333;
}
ul {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0px 5px 0px 0px;
  overflow: auto;
  height: 130.667vw;
  li {
    // width: 65px;
    height: 35px;
    line-height: 35px;
    border-radius: 15px;

    margin-bottom: 15px;
    padding: 0px 8px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .unactive {
    background: #f6f6f6;
  }
  .active {
    background: #c2daff;
  }

  // li:active {
  //   cursor: pointer;
  //   background: #c2daff;
  // }
}

.footer {
  margin-top: 5.334vw;
  .button-flex {
    display: flex;
    justify-content: space-around;
  }

  .button {
    width: 90px;
    height: 45px;
    line-height: 45px;
    border-radius: 22px;
    font-size: 17px;
    color: #fff;
  }

  .uncomfirm {
    background: #ddd;
  }
  .comfirm {
    background-image: linear-gradient(to right, #4f74ff, #0067f2);
  }
}
</style>
